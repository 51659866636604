import * as React from 'react';
import Navbar from '../components/common/Navbar/Navbar';
import TournamentDropdown from '../components/common/TournamentDropdown/TournamentDropdown';
import Podium from '../components/leaderboard/Podium/Podium';
import Leaderboard from '../components/leaderboard/Leaderboard/Leaderboard';

import './LeaderboardPage.css';
import Footer from '../components/common/Footer/Footer';
import {useEffect, useState} from "react";

const LeaderboardPage = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const baseURL = (environment && environment === "localhost") ? 'http://localhost:8900' : 'https://api.idols.gg';

    const [leaderboard, setLeaderboard] = useState(null);
    const [podiumPlayerRankingsList, setPodiumPlayerRankingsList] = useState(null);
    const [remainingPlayerRankingsList, setRemainingPlayerRankingsList] = useState(null);


    useEffect(() => {
        fetchLeaderboard();
    }, []);

    const fetchLeaderboard= () => {
        try {
            const params = {
                serverId: '1077434526571180072',
                tournamentId: 'MSI_2023'
            }

            fetch(`${baseURL}/leaderboard?` + new URLSearchParams(params))
                .then((res) => {
                    if (res.ok) {
                        res.json().then((jsonBody) => {
                            setLeaderboard(jsonBody.data);

                            if(jsonBody.data.playerRankingList) {
                                setPodiumPlayerRankingsList(jsonBody.data.playerRankingList.slice(0,3));
                                setRemainingPlayerRankingsList(jsonBody.data.playerRankingList.slice(3));
                            }
                        })
                    }
                })
                .catch()
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='content' id='leaderboard-container'>
                <Navbar/>
                <h2>MSI 2023</h2>

                <Podium playerRankingsList={podiumPlayerRankingsList}/>

                <div className='leaderboard-wrapper'>
                    <Leaderboard playerRankingsList={remainingPlayerRankingsList}/>
                </div>

            </div>

            <Footer/>
        </>
    );
}

export default LeaderboardPage;