import "./common-styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App/App.js";

//firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBV-A6MMEeawSvkwSfXKuk93sXZchz_Iws",
    authDomain: "idolsgg-frontend.firebaseapp.com",
    projectId: "idolsgg-frontend",
    storageBucket: "idolsgg-frontend.appspot.com",
    messagingSenderId: "442767148735",
    appId: "1:442767148735:web:47d9f7912d917f631a280a",
    measurementId: "G-8Y801BKWLC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App analytics={analytics}/>
    </BrowserRouter>
  </React.StrictMode>
);
