import * as React from 'react';
import './MainStage.css';

const MainStage = () => {
    return (
        <div style={{paddingLeft: '2rem', paddingRight: '2rem'}}>
            <h2 style={{paddingBottom: '2rem'}}>Main Stage Brackets are Unavailable for MSI 2024</h2>
            <h4>Unfortunately, these aren't ready in time for the main stage.</h4>
            <br/>
            <h4>We hope to have these ready for you in future tournaments!</h4>
        </div>
    );
 }

export default MainStage;