import * as React from 'react';
import './SingleCardView.css';
import PickCard from '../PickCard/PickCard';
import {FaThLarge} from 'react-icons/fa';
import {Link} from 'react-router-dom';

const SingleCardView = () => {
    return (
        <>
            <PickCard/>
            <Link id='gallery-button' to='/pickems/crystal-ball/gallery'><FaThLarge size={24}/> View all questions</Link>
        </>
    );
 }

export default SingleCardView;