import * as React from 'react';
import './Toast.css';
import {FaTimes, FaExclamationCircle, FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

const Toast = ({ message, type, onRemove }) => {
    const typeToColor = {
        'success': '#478547',
        'failure': 'hsl(from var(--auburn) h 55 45)',
        'warning': 'var(--gold)',
        'info': '#1b5094'
    }

    const typeIcon = () => {
        const color = typeToColor[type];

        switch(type) {
            case 'success':
                return <FaCheckCircle color={color} size={32}></FaCheckCircle>
            case 'failure':
                return <FaTimesCircle color={color} size={32}></FaTimesCircle>
            case 'warning':
                return <FaExclamationCircle color={color} size={32}></FaExclamationCircle>
            case 'info':
                return <FaInfoCircle color={color} size={32}></FaInfoCircle>
            default:
                return null;
        }
    }

    return (
        <div className={`toast ${type}`} onClick={onRemove}>
            {typeIcon()}
            {message}
            <FaTimes></FaTimes>
        </div>
    );
};

export default Toast;