// Represents a tournament Bracket as a tree of Matches
export class Bracket {
    constructor(team1 = null, team2 = null, team3 = null, team4  = null) {
        if (team1 && team2 && team3 && team4) {
            this.match1 = new Match(team1, team2);
            this.match2 = new Match(team3, team4);
            this.match3 = new Match(); // Teams will be determined later
            this.losers1 = new Match(); // Teams will be determined later
            this.losers2 = new Match(); // Teams will be determined later

            this.match3.left = this.match1;
            this.match3.right = this.match2;
            this.losers1.left = this.match1;
            this.losers1.right = this.match2;
            this.losers2.left = this.losers1;
            this.losers2.right = this.match3;
        } else {
            // Initialize as null if no teams are provided, used for deserialization
            this.match1 = null;
            this.match2 = null;
            this.match3 = null;
            this.losers1 = null;
            this.losers2 = null;
        }
    }

    setWinner(matchIndex, teamNumber) {
        // // Losers bracket match indices are represented as negative (e.g. lower bracket match #1 is -1)
        // // If changing earlier matches, reset the results of dependent (future) matches
        // let match;
        // switch (matchIndex) {
        //     case 1:
        //         match = this.match1;
        //         this.match3.resetResults();
        //         this.losers1.resetResults();
        //         this.losers2.resetResults();
        //         break;
        //     case 2:
        //         match = this.match2;
        //         this.match3.resetResults();
        //         this.losers1.resetResults();
        //         this.losers2.resetResults();
        //         break;
        //     case 3:
        //         match = this.match3;
        //         this.losers2.resetResults();
        //         break;
        //     case -1:
        //         match = this.losers1;
        //         this.losers2.resetResults();
        //         break
        //     case -2:
        //         match = this.losers2;
        //         break;
        //     default:
        //         return;
        // }
        //
        // let winner;
        // let loser;
        // switch(teamNumber) {
        //     case 1:
        //         winner = match.team1;
        //         loser = match.team2;
        //         break;
        //     case 2:
        //         winner = match.team2;
        //         loser = match.team1;
        //         break;
        //     default:
        //         return;
        // }
        //
        // match.setWinner(winner);
        // match.setLoser(loser);
        // this.match3.updateTeams();
        // this.losers1.updateTeamsLoser();
        // this.losers2.updateTeamsMixed();
    }

    // Returns true if the given match's two team slots have been filled from previous matches
    isValid(matchIndex) {

        let match;
        switch (matchIndex) {
            case 1:
                match = this.match1;
                this.match3.resetResults();
                break;
            case 2:
                match = this.match2;
                this.match3.resetResults();
                break;
            case 3:
                match = this.match3;
                break;
            case -1:
                match = this.losers1;
                break
            case -2:
                match = this.losers2;
                break;
            default:
                console.log('Invalid match index.');
                return;
        }

        return match.team1 && match.team2;
    }

    getStatus(matchIndex, teamNumber) {

        let match;
        switch (matchIndex) {
            case 1:
                match = this.match1;
                break;
            case 2:
                match = this.match2;
                break;
            case 3:
                match = this.match3;
                break;
            case -1:
                match = this.losers1;
                break
            case -2:
                match = this.losers2;
                break;
            default:
                console.log('Invalid match index.');
                return;
        }

        return match.getStatus(teamNumber);
    }

    static from(otherBracket) {
        let bracket = new Bracket();

        bracket.match1 = Match.from(otherBracket.match1);
        bracket.match2 = Match.from(otherBracket.match2);
        bracket.match3 = Match.from(otherBracket.match3);
        bracket.losers1 = Match.from(otherBracket.losers1);
        bracket.losers2 = Match.from(otherBracket.losers2);

        // Re-assign the left and right relationships if necessary
        bracket.match3.left = bracket.match1;
        bracket.match3.right = bracket.match2;
        bracket.losers1.left = bracket.match1;
        bracket.losers1.right = bracket.match2;
        bracket.losers2.left = bracket.losers1;
        bracket.losers2.right = bracket.match3;

        return bracket;
    }
}

// Represents a single match with two teams, a winner, and a loser
class Match {
    constructor(team1 = null, team2 = null, winner = null, loser = null) {
        this.team1 = team1;
        this.team2 = team2;
        this.winner = winner;
        this.loser = loser;
        this.left = null;  // Left child match
        this.right = null; // Right child match
    }

    setWinner(winner) {
        this.winner = winner;
    }

    setLoser(loser) {
        this.loser = loser;
    }

    resetResults() {
        this.winner = null;
        this.loser = null;
    }

    updateTeams() {
        if (this.left && this.right) {
            this.team1 = this.left.winner;
            this.team2 = this.right.winner;
        }
    }

    updateTeamsLoser() {
        if(this.left && this.right) {
            this.team1 = this.left.loser;
            this.team2 = this.right.loser;
        }
    }

    updateTeamsMixed() {
        if(this.left && this.right) {
            this.team1 = this.left.winner;
            this.team2 = this.right.loser;
        }
    }

    getStatus(teamNumber) {
        if(!this.winner && !this.loser) {
            return '';
        }

        if(teamNumber === 1) {
            return this.winner === this.team1 ? 'winner' : 'loser';
        } else if(teamNumber === 2) {
            return this.winner === this.team2 ? 'winner' : 'loser';
        }
    }

    static from(data) {
        const match = new Match(data.team1, data.team2, data.winner, data.loser);
        if (data.left) match.left = Match.from(data.left);
        if (data.right) match.right = Match.from(data.right);
        return match;
    }
}