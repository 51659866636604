import * as React from 'react';
import './Profile.css';
import Navbar from '../../common/Navbar/Navbar';
import {useContext, useRef, useState} from 'react';
import {SessionContext} from '../../../contexts/SessionContext';
import Footer from '../../common/Footer/Footer';
import { FaEdit, FaTimes, FaPencilAlt, FaCheck } from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';

const Profile = () => {
    const {
        logout,
        getDiscordUserId,
        getGlobalUserId,
        userHasProvider,
        idolsProfile,
        updateDisplayName
    } = useContext(SessionContext);

    const [editEnabled, setEditEnabled] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('/images/champions/icon/Aatrox.png');

    const { innerWidth: width } = window;

    const fileInputRef = useRef(null);
    const inputRef = useRef(null);

    const navigate = useNavigate();

    const callUpdateDisplayName = async (newDisplayName) => {
        if (newDisplayName) {
            const res = await updateDisplayName(newDisplayName);
        } else {
        }
    }

    // TODO: HANDLE UPLOAD FAILURE IF THE NAME IS ALREADY TAKEN
    const handleSubmit = async (event) => {
        // Handle display name submission here
        if(inputRef.current && inputRef.current.value !== idolsProfile.display_name) {
            await callUpdateDisplayName(inputRef.current.value);
        }

        setEditEnabled(false);
    };

    const handleCancel = (event) => {
        setEditEnabled(false);
    };

    const handleUploadProfilePicture = (event) => {
        // handle upload file here

        const file = event.target.files[0];
        if (file) {
            // Process the file here, like uploading it or updating the state
            // Optionally, convert file to a URL and display as the new profile picture:
            const reader = new FileReader();
            reader.onloadend = () => {
                // Set the loaded image URL to the state and display it
                setProfileImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const accountType = idolsProfile?.account_type;
    const memberString = (accountType === 'founder') ? 'Founder Premium Member' : (accountType === 'premium') ? 'Premium Member' : 'Basic Member';

    return (
        <>
            <div className='content'>
                <Navbar/>
                <div className='profile-wrapper-box'>
                    <h2>Your Profile</h2>

                    <div className='profile-container'>
                        <div className='profile-picture-container'>
                            <div className='profile-picture'
                                 onMouseEnter={() => setHovering(true)}
                                 onMouseLeave={() => setHovering(false)}
                                 onClick={() => fileInputRef.current.click()}>
                                <img src={profileImageUrl} alt='Profile' />
                                {hovering && <FaPencilAlt className="edit-icon" size={24} />}
                            </div>

                            <button onClick={() => fileInputRef.current.click()}>Edit profile picture</button>

                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleUploadProfilePicture}
                                accept="image/*"  // Accepts only image files
                            />
                        </div>


                        {
                            editEnabled ?
                                <div className='profile-info'>
                                    <div className='display-name'>
                                        <input type='text' defaultValue={idolsProfile.display_name} ref={inputRef}></input>
                                        <button onClick={handleSubmit}>
                                            <FaCheck size={width > 480 ? 24 : 16}></FaCheck>
                                        </button>
                                        <button onClick={handleCancel}>
                                            <FaTimes size={width > 480 ? 24 : 16}></FaTimes>
                                        </button>
                                    </div>

                                </div> :
                                <div className='profile-info'>
                                    <div className='display-name'>
                                        <h3>{idolsProfile?.display_name}</h3>
                                        <button onClick={() => setEditEnabled(true)}>
                                            <FaPencilAlt size={width > 480 ? 24 : 16}></FaPencilAlt>
                                        </button>
                                    </div>

                                    {/* TODO: make this dynamic and make the button link to the Upgrade page */}
                                    <div className='member-status'>
                                        <span>{memberString}</span>
                                        {
                                            (accountType === 'basic') &&
                                            <a href={'/upgrade'}>
                                                <button className='primary-button' style={{padding: '0.25rem 0.5rem'}}>Upgrade</button>
                                            </a>
                                        }
                                    </div>
                                </div>
                        }
                    </div>

                    <div className='past-tournaments'>
                        <h3>Your Past Tournaments</h3>
                        <p>We're still working on bringing your past tournaments to your profile. Check back soon to
                        see information about which tournaments you have participated in or are currently participating in!
                        </p>
                    </div>

                    <button
                        className='red-button large-button'
                        onClick={() => {
                            logout();
                            navigate('/');
                        }}>
                        Log Out
                    </button>
                </div>
            </div>

            <Footer/>
        </>

    );
 }

export default Profile;