import React from 'react';
import Navbar from '../components/common/Navbar/Navbar';
import CombinedTitleSection from '../components/pickems/CombinedTitleSection/CombinedTitleSection';
import {Outlet} from 'react-router-dom';

const Pickems = () => {
    return (
        <>
            <div className='content'>
                <Navbar/>
                <CombinedTitleSection/>
                <Outlet/>

            </div>

        </>
    );
}

export default Pickems;