import * as React from 'react';
import './Playins.css';
import {useContext, useEffect, useState} from 'react';
import { Bracket } from './Bracket';
import {PickemsContext} from "../../../contexts/PickemsContext";
import {removeNonLetters} from '../../../helpers';
import Footer from '../../common/Footer/Footer';

const Playins = () => {
    const [bracket1, setBracket1] = useState(null);
    const [bracket2, setBracket2] = useState(null);
    
    const notCompleteMessage = 'Previous round incomplete';
    const imageFilePath = '/images/teams/';

    const { userPicks, updateBracketUserPick} = useContext(PickemsContext);


    useEffect(() => {
        let group1Bracket;
        if (userPicks && userPicks.playinBracketAnswers && userPicks.playinBracketAnswers.group1) {
            // Decompose user's group1 bracket from the database into a Bracket object
            group1Bracket = Bracket.from(userPicks.playinBracketAnswers.group1);
        } else {
            // Default starting bracket
            group1Bracket = new Bracket('T1', 'Estral Esports', 'FlyQuest', 'PSG Talon');
        }

        let group2Bracket;
        if (userPicks && userPicks.playinBracketAnswers && userPicks.playinBracketAnswers.group2) {
            // Decompose user's group2 bracket from the database into a Bracket object
            group2Bracket = Bracket.from(userPicks.playinBracketAnswers.group2);
        } else {
            // Default starting bracket
            group2Bracket = new Bracket('Top Esports', 'LOUD', 'Fnatic', 'GAM Esports');
        }

        setBracket1(group1Bracket);
        setBracket2(group2Bracket);
    }, [userPicks]);

    const handleClick = (event, bracket) => {

        // If the text or img within the icon was clicked, target the parent icon instead
        let target = event.target;
        if (!target.className.includes('team')) {
            target = target.parentElement;
        }

        // If the user clicks a team already selected as a winner, no need to update bracket
        if (target.className.includes('winner')) {
            return;
        }

        const matchNumber = Number(target.parentElement.getAttribute('data-matchnumber'));
        const teamNumber = Number(target.getAttribute('data-key'));

        if(!bracket.isValid(matchNumber)) {
            return;
        }

        if(bracket === bracket1) {
            const updatedBracket1 = Bracket.from(bracket1);
            updatedBracket1.setWinner(matchNumber, teamNumber);
            setBracket1(updatedBracket1);
            updateBracketUserPick('group1', updatedBracket1);
        } else {
            const updatedBracket2 = Bracket.from(bracket2);
            updatedBracket2.setWinner(matchNumber, teamNumber);
            setBracket2(updatedBracket2);
            updateBracketUserPick('group2', updatedBracket2);
        }
    }

    const buildDoubleElimBracket = (bracket) => {
        return (
            <>
                <div className='bracket-wrapper'>
                    <div className='round-labels'>
                        <span className='round-label'>Upper Bracket Round 1 (10 points each)</span>
                        <span className='round-label'>Upper Bracket Round 2 (20 points)</span>
                        <span className='round-label'></span>
                    </div>
                    <div className='bracket'>

                        {/* First round teams are hard coded */}
                        <div className='round'>
                            <div className='match' data-matchnumber={1}>
                                <span
                                    data-key={1}
                                    className={`team ${bracket?.getStatus(1, 1) ?? ''}`}
                                    onClick={(event) => handleClick(event, bracket)}>
                                {
                                    bracket?.match1.team1 ?
                                        <img
                                            style={{objectFit: 'contain'}}
                                            src={imageFilePath + removeNonLetters(bracket?.match1.team1) + '.webp'}
                                            alt=''/> :
                                        ''
                                }
                                    {bracket?.match1.team1}
                            </span>
                                <span
                                    data-key={2}
                                    className={`team ${bracket?.getStatus(1, 2) ?? ''}`}
                                    onClick={(event) => handleClick(event, bracket)}>
                                {
                                    bracket?.match1.team2 ?
                                        <img
                                            style={{objectFit: 'contain'}}
                                            src={imageFilePath + removeNonLetters(bracket?.match1.team2) + '.webp'}
                                            alt=''/> :
                                        ''
                                }
                                    {bracket?.match1.team2}
                            </span>
                            </div>
                            <div className='match' data-matchnumber={2}>
                                <span
                                    data-key={1}
                                    className={`team ${bracket?.getStatus(2, 1) ?? ''}`}
                                    onClick={(event) => handleClick(event, bracket)}>
                                {
                                    bracket?.match2.team1 ?
                                        <img
                                            style={{objectFit: 'contain'}}
                                            src={imageFilePath + removeNonLetters(bracket?.match2.team1) + '.webp'}
                                            alt=''/> :
                                        ''
                                }
                                    {bracket?.match2.team1}
                            </span>
                                <span
                                    data-key={2}
                                    className={`team ${bracket?.getStatus(2, 2) ?? ''}`}
                                    onClick={(event) => handleClick(event, bracket)}>
                                {
                                    bracket?.match2.team2 ?
                                        <img
                                            style={{objectFit: 'contain'}}
                                            src={imageFilePath + removeNonLetters(bracket?.match2.team2) + '.webp'}
                                            alt=''/> :
                                        ''
                                }
                                    {bracket?.match2.team2}
                            </span>
                            </div>
                        </div>


                        <div className='match' data-matchnumber={3}>
                            <span
                                data-key={1}
                                className={`team ${bracket?.getStatus(3, 1) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.match3.team1 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.match3.team1) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.match3.team1 ?? notCompleteMessage}
                        </span>
                            <span
                                data-key={2}
                                className={`team ${bracket?.getStatus(3, 2) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.match3.team2 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.match3.team2) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.match3.team2 ?? notCompleteMessage}
                        </span>
                        </div>
                        <div className='match'>
                            <span className='subtitle' style={{color: 'var(--gold)'}}>Advances to next stage</span>
                            <span className='advance'>
                            {
                                bracket?.match3.winner ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.match3.winner) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.match3.winner ?? notCompleteMessage}
                        </span>
                        </div>
                    </div>
                </div>

                <div className='bracket-wrapper'>
                    <div className='round-labels'>
                        <span className='round-label'>Lower Bracket Round 1 (20 points)</span>
                        <span className='round-label'>Lower Bracket Round 2 (30 points)</span>
                        <span className='round-label'></span>
                    </div>
                    <div className='bracket'>
                        <div className='match' data-matchnumber={-1}>
                            <span
                                data-key={1}
                                className={`team ${bracket?.getStatus(-1, 1) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.losers1.team1 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.losers1.team1) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.losers1.team1 ?? notCompleteMessage}
                        </span>
                            <span
                                data-key={2}
                                className={`team ${bracket?.getStatus(-1, 2) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.losers1.team2 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.losers1.team2) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.losers1.team2 ?? notCompleteMessage}
                        </span>
                        </div>
                        <div className='match' data-matchnumber={-2}>
                            <span
                                data-key={1}
                                className={`team ${bracket?.getStatus(-2, 1) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.losers2.team1 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.losers2.team1) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.losers2.team1 ?? notCompleteMessage}
                        </span>
                            <span
                                data-key={2}
                                className={`team ${bracket?.getStatus(-2, 2) ?? ''}`}
                                onClick={(event) => handleClick(event, bracket)}>
                            {
                                bracket?.losers2.team2 ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.losers2.team2) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.losers2.team2 ?? notCompleteMessage}
                        </span>
                        </div>
                        <div className='match'>
                            <span className='subtitle' style={{color: 'var(--gold)'}}>Advances to next stage</span>
                            <span className='advance'>
                            {
                                bracket?.losers2.winner ?
                                    <img
                                        style={{objectFit: 'contain'}}
                                        src={imageFilePath + removeNonLetters(bracket?.losers2.winner) + '.webp'}
                                        alt=''/> :
                                    ''
                            }
                                {bracket?.losers2.winner ?? notCompleteMessage}
                        </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className='playins-container'>
            <div className='double-elim-bracket' id='group1'>
                <h4>Group A</h4>
                {buildDoubleElimBracket(bracket1)}
            </div>

            <div className='double-elim-bracket' id='group2'>
                <h4>Group B</h4>
                {buildDoubleElimBracket(bracket2)}
            </div>

            <Footer/>
        </div>
    );
 }

export default Playins;