import * as React from 'react';
import {NavLink} from 'react-router-dom';
import './Navbar.css';
import {useState, useEffect, useRef, useContext} from 'react';
import {FaBars} from 'react-icons/fa';
import {SessionContext} from "../../../contexts/SessionContext";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navbarRef = useRef(null);
    const {
        session, isSessionLoading, login, logout, supabaseClient, getDiscordUserId, getGlobalUserId
    } = useContext(SessionContext);

    const imageFilePath = process.env.PUBLIC_URL + '/images/idols_logo_svg_text.svg';

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <nav role='navigation' className='navbar sticky' ref={navbarRef}>
            <button type='button' className='menu-toggle' onClick={toggleMenu}><FaBars size='1.25rem' color='var(--sea-salt)'/></button>
            <ul className={`navbar-left ${isOpen ? 'open' : ''}`}>
                <li>
                    <NavLink to='/' onClick={closeMenu}>
                        <img src={imageFilePath} height={32} alt='idols.gg logo' className='navbar-logo'/>
                    </NavLink>
                </li>
                <li><NavLink to='/' onClick={closeMenu}>Home</NavLink></li>
                <li><NavLink to='/pickems' onClick={closeMenu}>Pickems</NavLink></li>
                <li><NavLink to='/leaderboard' onClick={closeMenu}>Leaderboard</NavLink></li>
                <li><NavLink to='/upgrade' onClick={closeMenu} style={{color: 'var(--gold)'}}>Prizes & Tiers</NavLink></li>
            </ul>
            <ul className='navbar-right'>
                {session && !isSessionLoading
                    ? (<li><NavLink to='/login' onClick={closeMenu}>Profile</NavLink></li>)
                    : (<li><NavLink to='/login' onClick={closeMenu} className='primary-button'>Login</NavLink></li>)
                }
            </ul>
        </nav>
    );
}

export default Navbar;