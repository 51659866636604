export const underscoresToSpaces = (str) => {
    return str.replace(/_/g, ' ');
};

export const spacesToUnderscores = (str) => {
    return str.replace(/ /g, '_');
}

export const removeNonLetters = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, '')
};

export const camelCaseToSpaces = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim();
}

export const checkStringIncludes = (mainString, subString) => {
    if(subString === '') {
        return true;
    }

    // Remove non-alphabetic characters and convert to lowercase
    const cleanMain = mainString.replace(/[^a-zA-Z]/g, '').toLowerCase();
    const cleanSub = subString.replace(/[^a-zA-Z]/g, '').toLowerCase();

    let subIndex = 0;
    for (let i = 0; i < cleanMain.length; i++) {
        if (cleanMain[i] === cleanSub[subIndex]) {
            subIndex++;
            if (subIndex === cleanSub.length) {
                return true;
            }
        }
    }

    return false;
};