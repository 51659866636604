import * as React from 'react';
import './Footer.css';
import privacyPolicyPDF from '../../../../Koolisoo-Studios-Privacy-Policy.pdf';
import {useRef, useState} from 'react';

const Footer = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const modalRef = useRef();

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <footer className='footer'>
            <div style={{display: 'flex', gap: '0.75rem'}}>
                <span style={{fontSize: '0.75rem'}}>&copy; 2023 Koolisoo Studios</span>
                <button onClick={handleOpen} style={{fontSize: '0.75rem'}} id='tos-button'>Terms</button>
                <a href={privacyPolicyPDF} target="_blank" style={{fontSize: '0.75rem'}} rel='noreferrer'>Privacy Policy</a>
            </div>
            <i style={{fontSize: '10px'}}>Idols.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc..</i>

            <div id='terms-modal' className={modalOpen ? 'open' : ''} ref={modalRef}>
                <div className="terms-modal-dialog">
                    <span className="close" onClick={handleClose}>&times;</span>
                    <div className='terms-content'>
                        <h2>IDOLS.GG TERMS OF USE</h2>

                        <p>By visiting and using idols.gg (the “website”), you accept and agree to be bound by these Website Terms of Use, including our Privacy Policy (which is part of these Website Terms of Use).</p>
                        <p>Koolisoo Studios LLC (“we”, “our”, or “us”), as owner of the website, reserves the right to amend or modify these Website Terms of Use at any time and without notice. The latest version of these Website Terms of Use will always be posted on the website.</p>
                        <p>The terms “you” and “your” refers to anyone who:</p>
                        <ul>
                            <li>uses, visits and/or views the website,</li>
                            <li>accesses any of our content, or</li>
                            <li>purchases any content, product(s), or service(s) from us.</li>
                        </ul>
                        <p>By using our website, you agree to our Website Terms of Use at the time you access the website (including any changes since you last visited the site). You must not access or use the website if you do not wish to be bound by these Website Terms of Use.</p>
                        <p>You must be at least 18 years old or of necessary age in your country of residence in order to use our website.</p>
                        <h2>Intellectual Property (including Copyright)</h2>
                        <p>We reserve all rights in and to our common law and registered trademarks, service marks, copyrights, and other intellectual property rights that exist on our website and in all viewable and downloadable content (including: text, images, video, music, sounds, layout, designs, source code, and any other protectable content) which belongs to us or any of our partners (“Intellectual Property”).</p>
                        <p>You cannot use, copy, modify, adapt, reproduce, store, distribute, print, display, perform, or publish any of our Intellectual Property except in the ways we specifically allow you to in these Website Terms of Use or with prior written consent from us.</p>
                        <p>For all purchased products and services, please also refer to any Terms &amp; Conditions for that specific product or service.</p>
                        <p>In short, we own all the legal rights to our free and paid content. We give you some limited rights to use our stuff only in certain ways (even if you paid for it). You cannot do anything you want with our content, only what is allowed in the these Website Terms of Use and any Terms &amp; Conditions for anything you’ve purchased. So even if you love our stuff, you are not allowed to share it with your friends (they need to buy their own)!</p>
                        <h2>NO WARRANTIES</h2>
                        <p>ALL OF OUR CONTENT, INFORMATION, PRODUCTS AND/OR SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND INCLUDING THE WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PURPOSE, EXPRESS OR IMPLIED TO THE FULL EXTENT PERMISSIBLE BY LAW. WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONTENT, INFORMATION, MATERIALS, PRODUCTS AND/OR SERVICES PROVIDED ON THIS WEBSITE. WE MAKE NO WARRANTIES THAT THE WEBSITE WILL PERFORM OR OPERATE TO MEET YOUR REQUIREMENTS OR THAT THE INFORMATION PRESENTED HERE WILL BE COMPLETE, CURRENT OR ERROR-FREE. WE DISCLAIM ALL WARRANTIES, IMPLIED AND EXPRESS FOR ANY PURPOSE TO THE FULL EXTENT PERMITTED BY LAW.</p>
                        <h2>Testimonials Disclaimer</h2>
                        <p>Any testimonials included on our website(s), programs, products, and/or services are real-world examples and stories of other people’s experiences with our content, programs, products and/or services. These testimonials are NOT intended to serve as a guarantee that you will achieve the same or similar results. Each individual’s performance is different and your results will vary accordingly.</p>
                        <h2>Earnings & Results Disclaimer</h2>
                        <p>We make no income/financial claims, nor guarantee of any kind regarding the potential income or savings that can be generated through your use of any of our free or paid content, products, and/or services.</p>
                        <p>Any income reports, product reviews, and testimonials we may publish from our business or from our current or former customers are strictly for informational purposes only.</p>
                        <p>Past results are not an indication or promise of your results. There is no guarantee you will earn any money or save any money using any of our materials, and your revenue and liabilities are dependent solely on you and your actions or non-actions.</p>
                        <h2>Advertisement Disclaimer</h2>
                        <p>You may have ended up on our website by clicking on an advertisement from a different site, social platform, or app. This is because we occasionally pay for advertising in order to reach people who would most benefit from our offers, and one of these advertisements may have been shown to you. We do not have direct control over who exactly is shown our advertisements.</p>
                        <p>Our website is independent and belongs to us. All of the content, products, and/or services available on it are our own. We do not claim to be endorsed by, or affiliated with, any other site, platform, or advertising provider.</p>
                        <h2>Limitation of Liability</h2>
                        <p>You agree that we (and/or our officers, employees, successors, shareholders, joint venture partners or anyone else working with us) shall NOT be liable for any direct, indirect, incidental, consequential, equitable, special, punitive, exemplary or any other damages resulting from your use of this website including but not limited to all the content, information, products, services and graphics presented here.</p>
                        <p>You expressly agree that your use of the website is at your sole risk and that you are solely responsible for the accuracy of any information you provide, the outcome of your actions or non-actions, personal and/or business results, and for all other use in connection with our website, content, products, and/or services.</p>
                        <p>You also expressly agree that we and/or our officers, employees, successors, shareholders, joint venture partners or anyone else working with us shall not be liable to you for any damages, even if foreseeable, resulting from 1) any errors or omissions on the website, delay or denial of any products or services, failure of performance of any kind, interruption in the operation and your use of the website, website attacks including computer virus, hacking of information, and any other system failures; 2) any loss of income, use, data, revenue, profits, business or any goodwill related to the website, our products, or our services; 3) any theft or unauthorized access by third party of your information from the website regardless of our negligence; and 4) any use or misuse of the content, information, products and/or services offered here.</p>
                        <p>This limitation of liability shall apply whether such liability arises from negligence, breach of contract, tort or any other legal theory of liability. You agree that we provide no express or implied guarantees to you for the content presented here, and you accept that no particular results are being promised to you here.</p>
                        <p>We do NOT exclude any liability for death or personal injury caused by the use of our website (as unlikely as that is) or any other liability which we legally cannot exclude based on the laws where you are located at the time you use our site.</p>
                        <p>Our maximum total liability to you shall not exceed the total purchase price of any products and/or services that we have received your payment for.</p>
                        <h2>Indemnification</h2>
                        <p>Your use of our website and content is voluntary. You shall indemnify us and our affiliates, distributors, licensors, and agents and all of our and their directors, employees, and agents against, holding all harmless from any and all lawsuits, claims, expenses (including reasonable legal fees), settlements, damages, judgments and the like arising from your use of our websites and content or your failure to maintain the confidentiality or security of your password or access rights.</p>
                        <h2>Privacy</h2>
                        <p>We care about your privacy online do everything in our power to respect the privacy of your personal information. Your acceptance of our Privacy Policy is expressly incorporated into these Website Terms of Use. Please review our Privacy Policy for more information.</p>
                        <h2>Publicity</h2>
                        <p>If you make a provide a testimonial, or make a public comment about us or any of our content, products, and/or services on any public website or social media platform, we may use any part of that feedback as well as your first name, business name (if applicable), and logo in our marketing materials.</p>
                        <p>We will never say or post anything about you that would damage your reputation or your business. You may request that we don’t use this information for marketing by emailing us at <a href="mailto:paul.zbarcea@gmail.com">paul.zbarcea@gmail.com</a>.</p>
                        <h2>Availability &amp; Web Access</h2>
                        <p>Your use of our website and any associated services may sometimes be subject to interruption or delay. Due to the nature of the Internet and electronic communications, we and our service providers do not make any warranty that our websites or any associated services will be error free, without interruption or delay, or free from defects in design. We will not be liable to you should our websites or the services supplied through our websites become unavailable, interrupted or delayed for any reason.</p>
                        <h2>Malicious Code</h2>
                        <p>Although we try to prevent the introduction of viruses or other malicious code (together, “malicious code”) to our websites, we do not guarantee or warrant that our websites, or any data available therein, do not contain malicious code. We will not be liable for any damages or harm attributable to malicious code. You are responsible for ensuring that the process you employ for accessing our websites does not expose your computer system to the risk of interference or damage from malicious code.</p>
                        <h2>Third Party Links</h2>
                        <p>We may include links to web addresses that we do not own or have any control over. To the best of our knowledge, these links are safe. However, we do not guarantee or warrant that clicking any third party link does not include malicious code. We will not be liable for any damages or harm as a result of you clicking any third party links.</p>
                        <p><strong>Security</strong></p>
                        <p>Although we take care to protect the security of your contact information, you acknowledge the risk of unauthorized access to (or alteration of) your transmissions or data or of the information contained on your computer system or on our websites. We do not accept responsibility or liability of any nature for any losses you may sustain as a result of such unauthorized access or alteration. All information transmitted to or from you is transmitted at your own risk, and you assume all responsibility and risks arising in relation to your use of this website and the Internet. We do not accept responsibility for any interference or damage to your own computer system which may arise in connection with your accessing of our websites or any outbound hyperlinks.</p>

                        <p><strong>Governing Law</strong></p>
                        <p>You expressly waive any legal claims you may have now or in the future arising from or related to the website and our products/services.</p>

                        <p>In the event of a dispute, claim, or controversy arising from or relating to your use of this website that cannot be excluded by your express waiver above, these Website Terms of Use shall be construed and interpreted in accordance with the laws of United States without regard to conflict of laws principles. Any legal action must be brought in the courts of South Dakota, United States.</p>

                        <p><strong>No Waiver</strong></p>
                        <p>Any waiver or delay by us in enforcing any term or right under these Website Terms of Use will not constitute a waiver of any other or subsequent breach or right.</p>

                        <p><strong>Interpretation and Severability</strong></p>
                        <p>Headings and images are inserted for convenience and shall not affect the construction of these Website Terms of Use. The singular includes the plural and vice versa. The feminine includes the masculine and vice versa.</p>

                        <p>If any portion of these Website Terms of Use is held to be invalid or unenforceable, the invalid or unenforceable portion shall be construed in accordance with applicable law to the greatest extent possible to reflect the original intentions of the parties, and the remainder of the provisions shall remain in full force and effect.</p>

                        <p>If any provision in these Website Terms of Use shall be held by a court of competent jurisdiction to be contrary to law, that provision shall be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provisions in these Website Terms of Use will remain in full force and effect.</p>

                        <p>These Website Terms of Use supersede any existing communications or agreements, and are the full extent of the agreement between the parties relating to this subject matter.</p>

                        <p><strong>All Rights Reserved</strong></p>
                        <p>All rights not expressly granted in these Website Terms of Use are reserved by us. If you do not see a usage scenario here that applies to your intended usage, or for any questions, contact us at paul.zbarcea@gmail.com.</p>

                        <p><strong>Contact Information</strong></p>
                        <p>The owner of this website is Koolisoo Studios LLC located at 3916 N Potsdam Ave PMB 2975, Sioux Falls, SD 57104. You may contact us regarding these Website Terms of Use by email at paul.zbarcea@gmail.com.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;