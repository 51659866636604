import * as React from 'react';
import './CrystalBall.css';
import {Outlet} from 'react-router-dom';

const CrystalBall = () => {
    return (
        <>
            <Outlet/>
        </>
    );
 }

export default CrystalBall;