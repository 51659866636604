import '../common-styles.css';
import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Import Context
import PickemsContextProvider from "./contexts/PickemsContext"
import SessionContextProvider from "./contexts/SessionContext";
import {ToastProvider} from './contexts/ToastContext';

// Import Pages
import Pickems from "./pages/Pickems";
import CrystalBall from './components/pickems/CrystalBall/CrystalBall';
import Playins from './components/pickems/Playins/Playins';
import MainStage from './components/pickems/MainStage/MainStage';
import Login from "./pages/Login";
import Gallery from './components/pickems/Gallery/Gallery';
import SingleCardView from './components/pickems/SingleCardView/SingleCardView';
import LeaderboardPage from './pages/LeaderboardPage';
import Home from './pages/Home';
import Toast from './components/common/Toast/Toast';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Upgrade from "./pages/Upgrade";
import Profile from './components/login/Profile/Profile';

// Connect to Stripe using our publishable key (public key)
const environment = process.env.REACT_APP_ENVIRONMENT;
const stripePublicKey = (environment && environment === "localhost")
    ? 'pk_test_51PAJBHKvTEuFBhW32uhb9JY9Rv895ACsk1grvy34qJxs9YX62bxRWD8stY4Le3dvW2kqZEIto0ZxM8vGRNdDVllw001NGhuimU'
    : 'pk_live_51PAJBHKvTEuFBhW3udFhWjMrBDPliMLjXvDwGKGIFfGSXvw6zrVnaZ3h878qUJBfMDXtHLPIQNXq6CJdSyKh5hwG00lOB5UTYj';
const stripePromise = loadStripe(stripePublicKey);

class App extends Component {
    render() {
        return (
            <ToastProvider>
                <Elements stripe={stripePromise}>
                    <SessionContextProvider>
                        <PickemsContextProvider>
                                <Routes>
                                    <Route path='*' element={<Home />}></Route>
                                    <Route path='/' element={<Home />}></Route>
                                    <Route path='/profile' element={<Profile/>}></Route>
                                    <Route path='/login' element={<Login />}></Route>
                                    <Route path='/leaderboard' element={<LeaderboardPage />}></Route>
                                    <Route path='/upgrade' element={<Upgrade />}></Route>
                                    <Route path='/pickems' element={<Pickems />}>
                                        <Route index element={<Navigate replace to='/pickems/crystal-ball' />}></Route>
                                        <Route path='crystal-ball' element={<CrystalBall />}>
                                            <Route index element={<Navigate replace to='/pickems/crystal-ball/single'/>}></Route>
                                            <Route path='single' element={<SingleCardView />}></Route>
                                            <Route path='gallery' element={<Gallery />}></Route>
                                        </Route>
                                        <Route path='play-ins' element={<Playins />}></Route>
                                        <Route path='main-stage' element={<MainStage />}></Route>
                                    </Route>
                                </Routes>
                        </PickemsContextProvider>
                    </SessionContextProvider>
                </Elements>
            </ToastProvider>
        );
    }
}

export default App;