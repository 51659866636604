import * as React from 'react';
import Navbar from '../components/common/Navbar/Navbar';
import './Home.css';
import {Link} from 'react-router-dom';
import Footer from '../components/common/Footer/Footer';
import Toast from '../components/common/Toast/Toast';

const Home = () => {
    return (
        <>
            <div className='content'>
                <Navbar/>

                <div className='home-container'>
                    <div className='home-title'>
                        <span className='text-decoration'>WELCOME TO</span>
                        <h1>Idols.gg</h1>
                        <p className='home-subtitle'>Your home for LoL Pick'ems</p>
                    </div>

                    <div className='cta-section'>
                        <div id='why-choose-idols'>
                            <h4>Why choose Idols?</h4>
                            <ul id='benefits-list'>
                                <li><b>Pay what you want:</b> Play for free or pay a minimum of $1 for exclusive benefits.</li>
                                <li><b>Community fun:</b> Connect with fellow fans and rise through the ranks.</li>
                                <li><b>Exclusive prizes:</b> Place high on the leaderboard to earn exciting rewards.</li>
                            </ul>
                            <br/>
                            <p>Ready to test your prediction skills? Join thousands of fans in our <b>MSI Pick'ems</b> and compete for glory!</p>
                        </div>

                        <div className='button-container'>
                            <Link to='/pickems' role='button' className='primary-button large-button'>Start My MSI Pick'Ems</Link>
                            <span>Stay tuned for future tournaments!</span>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Home;