import * as React from 'react';
import './Leaderboard.css';
import GalleryCard from "../../pickems/Gallery/GalleryCard";

const LeaderboardRow = ({ place, username, points, avatarUrl }) => {
    return (
        <div className='leaderboard-row'>

            <div className='leaderboard-user-info'>
                <div style={{width: '2.5rem'}}>
                    <span>{place}</span>
                </div>

                <div className='leaderboard-profile'>
                    <img
                        className='profile-image'
                        src={avatarUrl}
                        alt={`${username} profile`}
                        loading={'lazy'}
                        onError={(e) => { e.target.onerror = null; e.target.src = "/images/MSI-logo.png"; }} // Fallback for error in image loading
                    />
                    <span>{username}</span>
                </div>
            </div>

            <span>{points} pts</span>

        </div>
    );
};

const Leaderboard = ( {playerRankingsList}) => {
    if (playerRankingsList) {
        return (
            <div className='leaderboard'>
                {
                    playerRankingsList.map(playerRanking => (
                        <LeaderboardRow
                            place={playerRanking.leaderboard_position}
                            username={playerRanking.userName}
                            points={playerRanking.points}
                            avatarUrl={`https://cdn.discordapp.com/avatars/${playerRanking.userId}/${playerRanking.userAvatar}`}
                        />
                    ))
                }
            </div>
        );
    }
 }

export default Leaderboard;