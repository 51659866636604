import * as React from 'react';
import './GalleryCard.css';
import {useContext, useEffect, useRef, useState} from 'react';
import {checkStringIncludes, spacesToUnderscores} from '../../../helpers';
import {FaChevronLeft, FaChevronRight, FaTimes} from 'react-icons/fa';
import {PickemsContext} from "../../../contexts/PickemsContext";
import {SessionContext} from '../../../contexts/SessionContext';
import {useToasts} from '../../../contexts/ToastContext';

const GalleryCard = ( { questionIndex }) => {
    const [activeChoice, setActiveChoice] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [dominantColor, setDominantColor] = useState('white');
    const [pointValue, setPointValue] = useState(null);
    const [type, setType] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const modalRef = useRef(null);
    const modalMenuRef = useRef(null);

    const { data : itemData } = require('../../../../data/items.json');
    const { serverQuestions, getServerQuestionData, getSingleUserPick, updateSingleUserPick, userPicks, numberSubmitted, setNumberSubmitted } = useContext(PickemsContext);
    const { session } = useContext(SessionContext);
    const { addToast } = useToasts();

    const fetchCardData = () => {
        // Hard-coded sample response:
        // const data = {
        //     userChoice: `3002`,
        //     questionText: 'Which champion will be picked the most?',
        //     pointValue: 20,
        //     type: 'item',
        //     dominantColor: 'var(--auburn)'
        // };

        const data = {...getSingleUserPick(questionIndex), ...getServerQuestionData(questionIndex), dominantColor: 'var(--auburn)'};

        const { userChoice = '', questionText, pointValue, questionType, dominantColor } = data;
        setActiveChoice(userChoice);
        setQuestionText(questionText);
        setPointValue(pointValue);
        setType(questionType);
        setDominantColor(dominantColor);
    };

    useEffect(() => {
        fetchCardData();

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, [userPicks, serverQuestions]);

    const getItemName = (itemNumber) => {
        if (!itemNumber) {
            return null;
        }

        if(type !== 'item') {
            return itemNumber;
        }

        return itemData[itemNumber].name;
    };

    const getIconImagePath = (imageName) => {

        let baseFilePath = process.env.PUBLIC_URL + '/images';
        let extension = '.png';

        if(!imageName) {
            return baseFilePath + '/MSI-logo' + extension;
        }

        // eslint-disable-next-line default-case
        switch(type) {
            case 'champion':
                baseFilePath += '/champions/icon';
                break;
            case 'item':
                baseFilePath += '/items';
                break;
            case 'player':
                baseFilePath += '/players';
                extension = '.webp';
                break;
            case 'team':
                baseFilePath += '/teams';
                extension = '.webp';
                break;
            case 'dragon':
                baseFilePath += '/dragons';
                break;
            case 'side':
                baseFilePath += '/sides';
                break;
        }

        return baseFilePath + '/' + spacesToUnderscores(imageName) + extension;
    };

    const getCardImagePath = (imageName) => {

        let baseFilePath = '/images';
        let extension = '.png';

        if(!imageName) {
            return baseFilePath + '/MSI-logo' + extension;
        }

        // eslint-disable-next-line default-case
        switch(type) {
            case 'champion':
                baseFilePath += '/champions/full_splash';
                extension = '.jpg';
                break;
            case 'item':
                baseFilePath += '/items';
                break;
            case 'player':
                baseFilePath += '/players';
                extension = '.webp';
                break;
            case 'team':
                baseFilePath += '/teams';
                extension = '.webp';
                break;
            case 'dragon':
                baseFilePath += '/dragons';
                break;
            case 'side':
                baseFilePath += '/sides';
                break;
        }

        return baseFilePath + '/' + spacesToUnderscores(imageName) + extension;
    };

    const openModal = () => {

        // If there is an activeChoice, find that icon and make it active
        if(activeChoice) {

            let icon;
            if(modalMenuRef?.current) {
                const modalMenu = modalMenuRef.current;

                for(const modalMenuKey of modalMenu.children) {
                    if(modalMenuKey.id === activeChoice) {
                        icon = modalMenuKey;
                        break;
                    }
                }
            }

            if(icon) {
                icon.className = 'icon active';
            }
        }

        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSubmitEnabled(false);
    };

    const handleOutsideClick = (event) => {
        const modal = modalRef.current;

        if(modal && modal.contains(event.target) && !modal.children[0].contains(event.target)) {
            handleCancel();
        }
    };

    const handleInput = (event) => {
        setSearchInput(event.target.value);
    };

    const findActiveIcon = (event) => {
        let menu = event.target.parentElement;

        if(menu.className.includes('icon')) {
            menu = menu.parentElement;
        }
        for (const icon of menu.children) {
            if(icon.className.includes('active')) {
                return icon;
            }
        }

        return null;
    };

    const handleSelect = (event) => {

        let currentActiveIcon = findActiveIcon(event);

        // Remove the previous active icon
        if(currentActiveIcon) {
            currentActiveIcon.className = 'icon';
        }

        // If the text or img within the icon was clicked, target the parent icon instead
        let target = event.target;
        if(!target.className.includes('icon')) {
            target = target.parentElement;
        }

        // Set target to the new active icon
        target.className += ' active';

        // Enable submit button
        setSubmitEnabled(true);
    }

    const handleCancel = () => {

        // Remove the active icon before closing the modal
        let currentActiveIcon = document.getElementsByClassName('icon active');

        if(currentActiveIcon[0]) {
            currentActiveIcon[0].className = 'icon';
        }

        closeModal();
    };

    const handleSave = (event) => {
        addToast('MSI has started, Crystal ball picks are locked', 'warning');

        // if(type === 'numeric') {
        //     setActiveChoice(searchInput);
        //     updateSingleUserPick(questionIndex, searchInput);
        //
        //     closeModal();
        //
        //     return;
        // }
        //
        // let menu;
        // for (const child of event.target.parentElement.children) {
        //     if(child.className?.includes('modal-dialog')) {
        //         menu = child;
        //         break;
        //     }
        // }
        //
        // let currentActiveIcon;
        // for (const icon of menu.children) {
        //     if(icon.className.includes('active')) {
        //         currentActiveIcon = icon;
        //         break;
        //     }
        // }
        //
        // setActiveChoice(currentActiveIcon.id);
        //
        // // Update the UserPickem state, which will trigger a POST request to the database
        // updateSingleUserPick(questionIndex, currentActiveIcon.id);
        //
        // setNumberSubmitted(numberSubmitted + 1);
        // if(numberSubmitted >= 2 && !session) {
        //     addToast('You must be logged in for your picks to be saved!', 'warning');
        // }

        closeModal();
    };

    const getValueList = () => {

        switch(type) {
            case 'champion':
                return require('../../../../data/champions.json').data;
            case 'item':
                return require('../../../../data/items.json').data;
            case 'player':
                const teamRostersList = require('../../../../data/rosters.json').data;

                let allPlayersList = [];
                for(const team in teamRostersList) {
                    const roster = teamRostersList[team].roster;

                    for(const player in roster) {
                        allPlayersList.push(roster[player]);
                    }
                }

                return allPlayersList;
            case 'team':
                const teamList = require('../../../../data/rosters.json').data;

                let allTeamsList = [];
                for(const team in teamList) {
                    allTeamsList.push(
                        {
                            team: team,
                            plaintext: teamList[team].plaintext
                        }
                    );
                }

                return allTeamsList;
            case 'side':
                return require('../../../../data/sides.json').data;
            case 'dragon':
                return require('../../../../data/dragons.json').data;
            default:
                return;
        }
    };

    const renderModalMenu = () => {

        if(!type) {
            return;
        }

        const valueList = getValueList();

        let menuIcons = [];

        for (const value in valueList) {

            let id = value;
            let iconName = value;
            if(type === 'item') {
                iconName = getItemName(id);
            } else if (type === 'champion') {
                iconName = valueList[value].name;
            } else if (type === 'player') {
                id = valueList[value];
                iconName = id;
            } else if (type === 'team') {
                id = valueList[value].team;
                iconName = valueList[value].plaintext;
            } else if (type === 'dragon') {
                // Data is already valid
            } else if (type === 'side') {
                id = valueList[value];
                iconName = valueList[value];
            }

            if (checkStringIncludes(iconName, searchInput)) {
                let className = 'icon';
                if(id === activeChoice) {
                    className += ' active';
                }

                menuIcons.push(
                    <div className={className} id={id} key={id} onClick={handleSelect}>
                        <img src={getIconImagePath(id)} alt={`${iconName} icon`} draggable={false}/>
                        <span>{iconName}</span>
                    </div>
                )
            }
        }

        return (
            <div className='modal-dialog' ref={modalMenuRef}>
                {menuIcons}
            </div>
        )
    };

    return (
        <div className='gallery-card-wrapper'>
            <div className='gallery-card' onClick={openModal}>

                <div className='question-bar'>
                    <p>{questionText}</p>
                    <div className='point-value-container'>
                        <span id='point-circle'></span>
                        <span id='point-value'>{pointValue}</span>
                    </div>
                </div>

                <div
                    className='title'
                    style={ type === 'player' ?
                        {backgroundImage: `url(${getCardImagePath(activeChoice)})`, backgroundSize: 'contain'} :
                        {backgroundImage: `url(${getCardImagePath(activeChoice)})`}}>
                    <div className='title-content' style={type === 'dragon' ? {backdropFilter: 'blur(10px)'} : {}}>
                        {activeChoice}
                    </div>
                </div>

                <button className='pick-button'>
                    {activeChoice ? 'CHANGE PICK' : 'MAKE PICK'}<FaChevronRight size='1rem'/>
                </button>
            </div>

            <div className={`pick-modal ${modalOpen ? 'open' : ''}`} ref={modalRef}>
                <div className='pick-modal-content'>
                    {
                        type === 'numeric' ?

                            <>
                                <div className='modal-title'>
                                    <span>{questionText}</span>
                                    <span className='close-button' onClick={handleCancel}><FaTimes/></span>
                                </div>
                                <input
                                    className='numerical-input'
                                    type='number'
                                    defaultValue={activeChoice ? activeChoice : '0'}
                                    onChange={(event) => {
                                        setSubmitEnabled(true);
                                        handleInput(event);
                                }}/>
                                <button onClick={submitEnabled ? handleSave : null} id='submit-button' className={submitEnabled ? `` : 'disabled'}>Submit Choice</button>
                            </> :

                            <>
                                <div className='modal-title'>
                                    <span>{questionText}</span>
                                    <span className='close-button' onClick={handleCancel}><FaTimes/></span>
                                </div>
                                <input type='text' placeholder='Search' onChange={handleInput}/>
                                {renderModalMenu()}
                                <button onClick={submitEnabled ? handleSave : null} id='submit-button' className={submitEnabled ? `` : 'disabled'}>Submit Choice</button>
                            </>
                    }





                </div>
            </div>
        </div>
    );
}

export default GalleryCard;