import * as React from 'react';
import './ToastManager.css';
import Toast from './Toast';

const ToastManager = ({ toasts = [], removeToast }) => {
    return (
        <div className="toast-container">
            {toasts.map(toast => (
                <Toast key={toast.id} message={toast.message} type={toast.type} onRemove={() => removeToast(toast.id)} />
            ))}
        </div>
    );
};

export default ToastManager;