import * as React from 'react';
import './StageSelector.css';
import {NavLink} from 'react-router-dom';

const StageSelector = () => {
    return (
        <nav className='stage-selector'>
            <ul className='stages-list'>
                <li><NavLink to='/pickems/crystal-ball'>Crystal Ball</NavLink></li>
                <li><NavLink to='/pickems/play-ins'>Play-ins</NavLink></li>
                <li><NavLink to='/pickems/main-stage'>Main Stage</NavLink></li>
            </ul>
        </nav>
    );
}

export default StageSelector;