import {createContext, useEffect, useState} from "react";
import {createClient} from "@supabase/supabase-js";
import axios from "axios";

// Supabase URL and public key for authentication client
const supabaseUrl = 'https://idolsgg.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpsaWVrdmtjaGx6a3R1cHNwbmFiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM1NzUxODIsImV4cCI6MjAyOTE1MTE4Mn0.n5nxnCDh_jt8nDY4v2KvDOm4wiVDVgbozPJDV9W8E0I';
const supabaseClient = createClient(supabaseUrl, supabaseKey);

export const SessionContext = createContext(null);

const SessionContextProvider = ({children}) => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const baseURL = (environment && environment === "localhost") ? 'http://localhost:8900' : 'https://api.idols.gg';

    const [session, setSession] = useState(null);
    const [isSessionLoading, setIsSessionLoading] = useState(true);
    const [idolsProfile, setIdolsProfile] = useState(null);

    useEffect(() => {
        setIsSessionLoading(true);

        // Get the session using the Supabase client
        // sessions are stored in the browser's session storage (Application > Session)
        supabaseClient.auth.getSession().then(({data: {session}}) => {
            setSession(session);

            if (session && session.user) {
                const profileDataFromSession = generateUserProfileData(session);

                fetch(`${baseURL}/v2/getorcreateprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(profileDataFromSession)  // Convert the JavaScript object to a JSON string
                })
                    .then(res => {
                        if (res.ok) {
                            res.json().then((jsonBody) => {
                                if (jsonBody?.data) {
                                    setIdolsProfile(jsonBody.data);
                                }
                                //done loading
                                setIsSessionLoading(false);
                            })
                        } else {
                            setIdolsProfile(null);

                            //done loading
                            setIsSessionLoading(false);
                        }
                    })
                    .catch(error => {
                        setIsSessionLoading(false);
                        console.error('Error:', error);  // Handle any errors
                    });
            } else {
                setIdolsProfile(null);

                setIsSessionLoading(false);
            }
        });

        // Subscribe to changes in the session
        // This allows us to do things like re-render on logout.
        const {
            data: {subscription},
        } = supabaseClient.auth.onAuthStateChange((_event, session) => {
            setSession(session);

            if (session && session.user) {
                const profileDataFromSession = generateUserProfileData(session);

                fetch(`${baseURL}/v2/getorcreateprofile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(profileDataFromSession)  // Convert the JavaScript object to a JSON string
                })
                    .then(res => {
                        if (res.ok) {
                            res.json().then((jsonBody) => {
                                if (jsonBody?.data) {
                                    setIdolsProfile(jsonBody.data);
                                }
                                //done loading
                                setIsSessionLoading(false);
                            })
                        } else {
                            setIdolsProfile(null);

                            //done loading
                            setIsSessionLoading(false);
                        }
                    })
                    .catch(error => {
                        setIsSessionLoading(false);
                        console.error('Error:', error);  // Handle any errors
                    });
            } else {
                setIdolsProfile(null);

                setIsSessionLoading(false);
            }
        });

        // Get rid of the subscription once the component is unmounted so we don't keep an open WebSocket / memory leak
        return () => {
            subscription.unsubscribe();
        }
    }, []);

    // TODO: implement
    const login = () => {
        // this isn't used because we use the Auth component from auth-ui-react that handles everything for us
    }

    const logout = () => {
        // Signing out clears the session from the browser's storage
        // It also makes an entry in the supabase audit log / sessions database (but we don't really care about that)
        supabaseClient.auth.signOut().then(({error}) => {
            if (error) {
                //TODO: add failure toast
            } else {
                // Clear out the userPicks stored locally
                localStorage.setItem('userPicks', '{}');

                //TODO: add success toast
            }
        });
    };

    const generateUserProfileData = (transientSession) => {
        if (!transientSession || !transientSession.user) {
            return null;
        }

        let discordIdentity = null;
        // Parse the identities array to find the discord Identity
        for (const identity of transientSession.user.identities) {
            if (identity.provider === 'discord') {
                discordIdentity = identity;
            }
        }

        const user_id = transientSession.user.identities[0].user_id;
        const discord_id = discordIdentity?.identity_data?.provider_id;
        const google_id = null;
        const email = transientSession.user?.email;
        const display_name = discordIdentity?.identity_data?.full_name;
        // const display_name = discordIdentity?.identity_data?.custom_claims?.global_name;

        let toReturn = {};
        if (user_id)
            toReturn.user_id= user_id;
        if (discord_id)
            toReturn.discord_id= discord_id;
        if (google_id)
            toReturn.google_id= google_id;
        if (email)
            toReturn.email= email;
        if (display_name)
            toReturn.display_name= display_name;

        return toReturn;
    }

    const getDiscordIdentityHelper = () => {
        // Parse the identities array to find the discord Identity
        for (const identity of session.user.identities) {
            if (identity.provider === 'discord') {
                return identity;
            }
        }

        return null;
    }

    // Returns the Identity object related to Discord login from the user.identities array
    const getDiscordIdentity = () => {
        if (!session || isSessionLoading || !session.user) {
            return null;
        }

        return getDiscordIdentityHelper();
    }

    // return the Discord UserId string
    // example: "225761663788384256"
    const getDiscordUserId = () => {
        const discordIdentity = getDiscordIdentity();

        return discordIdentity?.identity_data?.provider_id;
    }

    // return the supabase UserId shared between all the user's linked identities
    // example: "fcb9ce1c-fba3-455b-8423-f03e7b25de32"
    const getGlobalUserId = () => {
        if (!session || isSessionLoading || !session.user) {
            return null;
        }

        return session.user.identities[0].user_id;
    }

    // Returns true if the user has this type of account linked
    // currently the supported providers are 'email, 'google', 'discord'
    const userHasProvider = (providerName) => {
        if (!session || isSessionLoading || !session.user) {
            return null;
        }

        return !!(session.user.app_metadata.providers.includes(providerName));
    }

    // Sends the POST request to update the display_name in the Database and returns the response code
    // codes: 200 = update successful, 405 = display name already in use, 500 = some other error
    const updateDisplayName = async (newDisplayName) => {
        const user_id = getGlobalUserId();
        if (!user_id) {
            return 500;
        }

        const updateBody = {
            user_id: user_id,
            display_name: newDisplayName
        };

        fetch(`${baseURL}/v2/idolsprofile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateBody)
        })
            .then(response => {
                if (response.ok) {
                    setIdolsProfile(idolsProfile => ({...idolsProfile, display_name: newDisplayName}));
                }
                return response.status;
            })
            .catch(error => {
                console.error('Error:', error);  // Handle any errors
                return 500;
            });
    }

    const updateIdolsProfileAccountType = (newAccountType) => {
        if (session && !isSessionLoading) {
            try {
                axios.post(`${baseURL}/v2/idolsprofile`, {
                    user_id: getGlobalUserId(),
                    account_type: newAccountType
                }).then((res) => {
                    if (res.status === 200) {
                        setIdolsProfile(idolsProfile => ({...idolsProfile, account_type: newAccountType}));
                    }
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <SessionContext.Provider value={{
            session,
            isSessionLoading,
            login,
            logout,
            supabaseClient,
            getDiscordIdentity,
            getDiscordUserId,
            getGlobalUserId,
            userHasProvider,
            idolsProfile,
            updateDisplayName,
            updateIdolsProfileAccountType
        }}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContextProvider