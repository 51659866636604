import * as React from 'react';
import './Podium.css';

const numberToOrdinal = (number) => {
    const lastDigit = number % 10;
    const secondLastDigit = Math.floor((number % 100) / 10);

    if (lastDigit === 1 && secondLastDigit !== 1) {
        return number + 'st';
    } else if (lastDigit === 2 && secondLastDigit !== 1) {
        return number + 'nd';
    } else if (lastDigit === 3 && secondLastDigit !== 1) {
        return number + 'rd';
    } else {
        return number + 'th';
    }
};

const PodiumIcon = ({ place, username, points, avatarUrl }) => {
    let color = 'var(--gold)';
    switch(place) {
        case 2:
            color = 'var(--silver)'
            break;
        case 3:
            color = 'var(--bronze)'
            break;
        default:
            break;
    }

    return (
        <div className='podium-icon'>
            <div className={`profile-pic ${place === 1 ? 'first' : ''}`} style={{borderColor: color}}>
                <img
                    src={avatarUrl}
                    alt='Profile Picture'
                    loading={'lazy'}
                    onError={(e) => { e.target.onerror = null; e.target.src = "/images/MSI-logo.png"; }} // Fallback for error in image loading
                />
                <div className='placement' style={{backgroundColor: color}}>{numberToOrdinal(place)}</div>
            </div>
            <span>{username}</span>
            <span>{points}</span>
        </div>
    );
};

const Podium = ({ playerRankingsList }) => {
    if (playerRankingsList) {
        const firstPlaceUsername = playerRankingsList[0].userName;
        const firstPlacePoints = playerRankingsList[0].points;
        const firstPlaceAvatarUrl = `https://cdn.discordapp.com/avatars/${playerRankingsList[0].userId}/${playerRankingsList[0].userAvatar}`;

        const secondPlaceUsername = playerRankingsList[1].userName;
        const secondPlacePoints = playerRankingsList[1].points;
        const secondPlaceAvatarUrl = `https://cdn.discordapp.com/avatars/${playerRankingsList[1].userId}/${playerRankingsList[1].userAvatar}`;

        const thirdPlaceUsername = playerRankingsList[2].userName;
        const thirdPlacePoints = playerRankingsList[2].points;
        const thirdPlaceAvatarUrl = `https://cdn.discordapp.com/avatars/${playerRankingsList[2].userId}/${playerRankingsList[2].userAvatar}`;

        return (
            <div className='podium'>
                <PodiumIcon place={2} username={secondPlaceUsername} points={secondPlacePoints}
                            avatarUrl={secondPlaceAvatarUrl}/>
                <PodiumIcon place={1} username={firstPlaceUsername} points={firstPlacePoints}
                            avatarUrl={firstPlaceAvatarUrl}/>
                <PodiumIcon place={3} username={thirdPlaceUsername} points={thirdPlacePoints}
                            avatarUrl={thirdPlaceAvatarUrl}/>
            </div>
        );
    }
 }

export default Podium;