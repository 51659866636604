import * as React from 'react';
import './PickCard.css';
import {FaChevronRight, FaChevronLeft, FaTimes} from 'react-icons/fa';
import {useContext, useEffect, useRef, useState} from 'react';
import {spacesToUnderscores, checkStringIncludes} from '../../../helpers';
import {PickemsContext} from "../../../contexts/PickemsContext";
import {useToasts} from '../../../contexts/ToastContext';
import {SessionContext} from '../../../contexts/SessionContext';

const PickCard = () => {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionsLength, setQuestionsLength] = useState(0);
    const [activeChoice, setActiveChoice] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [dominantColor, setDominantColor] = useState('white');
    const [pointValue, setPointValue] = useState(null);
    const [type, setType] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const modalRef = useRef(null);

    const { addToast } = useToasts();

    const { data : itemData } = require('../../../../data/items.json');
    const { serverQuestions, getServerQuestionData, getServerQuestionLength, userPicks, getSingleUserPick, updateSingleUserPick, numberSubmitted, setNumberSubmitted } = useContext(PickemsContext);
    const { session } = useContext(SessionContext);

    const fetchCardData = () => {

        const data = {...getSingleUserPick(questionIndex), ...getServerQuestionData(questionIndex), dominantColor: 'var(--auburn)'};

        const { userChoice = '', questionText, pointValue, questionType, dominantColor } = data;
        setActiveChoice(userChoice);
        setQuestionText(questionText);
        setPointValue(pointValue);
        setType(questionType);
        setDominantColor(dominantColor);

        setQuestionsLength(getServerQuestionLength());
    };

    useEffect(() => {
        fetchCardData();

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        }
    }, [userPicks, serverQuestions]);

    useEffect(() => {
        fetchCardData();
    }, [questionIndex]);

    const getItemName = (itemNumber) => {
        if(type !== 'item') {
            return itemNumber;
        }

        return itemData[itemNumber].name;
    };

    const getIconImagePath = (imageName) => {

        let baseFilePath = process.env.PUBLIC_URL + '/images';
        let extension = '.png';

        if(!imageName) {
            return baseFilePath + '/MSI-logo' + extension;
        }

        // eslint-disable-next-line default-case
        switch(type) {
            case 'champion':
                baseFilePath += '/champions/icon';
                break;
            case 'item':
                baseFilePath += '/items';
                break;
            case 'player':
                baseFilePath += '/players';
                extension = '.webp';
                break;
            case 'team':
                baseFilePath += '/teams';
                extension = '.webp';
                break;
            case 'dragon':
                baseFilePath += '/dragons';
                break;
            case 'side':
                baseFilePath += '/sides';
                break;
        }

        return baseFilePath + '/' + spacesToUnderscores(imageName) + extension;
    };

    const getCardImagePath = (imageName) => {

        let baseFilePath = process.env.PUBLIC_URL + '/images';
        let extension = '.png';

        if(!imageName) {
            return baseFilePath + '/MSI-logo' + extension;
        }

        // eslint-disable-next-line default-case
        switch(type) {
            case 'champion':
                baseFilePath += '/champions/full_splash';
                extension = '.jpg';
                break;
            case 'item':
                baseFilePath += '/items';
                break;
            case 'player':
                baseFilePath += '/players';
                extension = '.webp';
                break;
            case 'team':
                baseFilePath += '/teams';
                extension = '.webp';
                break;
            case 'dragon':
                baseFilePath += '/dragons';
                break;
            case 'side':
                baseFilePath += '/sides';
                break;
        }

        return baseFilePath + '/' + spacesToUnderscores(imageName) + extension;
    };

    const openModal = () => {

        // If there is an activeChoice, find that icon and make it active
        if(activeChoice) {
            const icon = document.getElementById(activeChoice);

            if(icon) {
                icon.className = 'icon active';
            }
        }

        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSubmitEnabled(false);
    };

    const handleOutsideClick = (event) => {
        const modal = modalRef.current;

        if(modal && modal.contains(event.target) && !modal.children[0].contains(event.target)) {
            handleCancel();
        }
    };

    const handleInput = (event) => {
        setSearchInput(event.target.value);
    };

    const handleSelect = (event) => {
        let currentActiveIcon = document.getElementsByClassName('icon active');

        // Remove the previous active icon
        if(currentActiveIcon[0]) {
            currentActiveIcon[0].className = 'icon';
        }

        // If the text or img within the icon was clicked, target the parent icon instead
        let target = event.target;
        if(!target.className.includes('icon')) {
            target = target.parentElement;
        }

        // Set target to the new active icon
        target.className += ' active';

        // Enable submit button
        setSubmitEnabled(true);
    }

    const handleCancel = () => {

        // Remove the active icon before closing the modal
        let currentActiveIcon = document.getElementsByClassName('icon active');

        if(currentActiveIcon[0]) {
            currentActiveIcon[0].className = 'icon';
        }

        closeModal();
    };

    const handleSave = (event) => {
        addToast('MSI has started, picks are locked', 'warning');

        // if(type === 'numeric') {
        //     setActiveChoice(searchInput);
        //     updateSingleUserPick(questionIndex, searchInput);
        //
        //     closeModal();
        //
        //     return;
        // }
        //
        // let currentActiveIcon = document.getElementsByClassName('icon active');
        //
        // setActiveChoice(currentActiveIcon[0].id);
        //
        // // Update the UserPickem state, which will trigger a POST request to the database
        // updateSingleUserPick(questionIndex, currentActiveIcon[0].id);
        //
        // setNumberSubmitted(numberSubmitted + 1);
        // if(numberSubmitted >= 2 && !session) {
        //     addToast('You must be logged in for your picks to be saved!', 'warning');
        // }

        closeModal();
    };

    // Navigates to the previous question
    const navigateBack = () => {
        if (questionsLength !== 0 && questionIndex !== 0) {
            setQuestionIndex(questionIndex - 1);
        }
    };

    // Navigates to the next question
    const navigateNext = () => {
        if (questionsLength !== 0 && questionIndex !== (questionsLength - 1)) {
            setQuestionIndex(questionIndex + 1);
        }
    };

    const getValueList = () => {

        switch(type) {
            case 'champion':
                return require('../../../../data/champions.json').data;
            case 'item':
                return require('../../../../data/items.json').data;
            case 'player':
                const teamRostersList = require('../../../../data/rosters.json').data;

                let allPlayersList = [];
                for(const team in teamRostersList) {
                    const roster = teamRostersList[team].roster;

                    for(const player in roster) {
                        allPlayersList.push(roster[player]);
                    }
                }

                return allPlayersList;
            case 'team':
                const teamList = require('../../../../data/rosters.json').data;

                let allTeamsList = [];
                for(const team in teamList) {
                    allTeamsList.push(
                        {
                            team: team,
                            plaintext: teamList[team].plaintext
                        }
                    );
                }

                return allTeamsList;
            case 'side':
                return require('../../../../data/sides.json').data;
            case 'dragon':
                return require('../../../../data/dragons.json').data;
            default:
                return;
        }
    };

    const renderModalMenu = () => {

        if(!type) {
            return;
        }

        const valueList = getValueList();

        let menuIcons = [];

        for (const value in valueList) {

            let id = value;
            let iconName = value;
            if(type === 'item') {
                iconName = getItemName(id);
            } else if (type === 'champion') {
                iconName = valueList[value].name;
            } else if (type === 'player') {
                id = valueList[value];
                iconName = id;
            } else if (type === 'team') {
                id = valueList[value].team;
                iconName = valueList[value].plaintext;
            } else if (type === 'dragon') {
                // Data is already valid
            } else if (type === 'side') {
                id = valueList[value];
                iconName = valueList[value];
            }

            if (checkStringIncludes(iconName, searchInput)) {
                let className = 'icon';
                if(id === activeChoice) {
                    className += ' active';
                }

                menuIcons.push(
                    <div className={className} id={id} key={id} onClick={handleSelect}>
                        <img src={getIconImagePath(id)} alt={`${iconName} icon`} draggable={false}/>
                        <span>{iconName}</span>
                    </div>
                )
            }
        }

        return (
            <div className='modal-dialog'>
                {menuIcons}
            </div>
        )
    };

    const getScrollText = () => {
        if(type === 'numeric') {
            return 'Tiebreaker';
        }

        return activeChoice || 'MSI 2024';
    };

    // TODO: fix bug where dynamically changing the scrolling text still uses the initially calculated length
    //  maybe make the animation using JS instead of CSS?
    //  EDIT -- not sure if this actually happens
    return (
        <div className='crystal-ball-wrapper'>

            <button className='back-button' onClick={navigateBack}>
                <FaChevronLeft/> Back
            </button>

            <div className='card-container'>
                {/* Background effects */}
                <div id='ellipse'
                     style={dominantColor ? {backgroundColor: dominantColor} : {backgroundColor: '#505050'}}></div>
                <div id='scroll-text-container' className='prevent-select'>
                    <p className='scroll-text'>{getScrollText()}</p>
                    <p className='scroll-text'>{getScrollText()}</p>
                    <p className='scroll-text'>{getScrollText()}</p>
                    <p className='scroll-text'>{getScrollText()}</p>
                </div>

                {/* Pick Card content */}
                <div className={`pick-card ${type}-card`} onClick={openModal}>
                    <div id='point-value-container'>
                        <span id='point-circle'></span>
                        <span id='point-value'>{pointValue}</span>
                    </div>
                    <div className='question-bar'>
                        <p>{questionText}</p>
                        <button className='pick-button'>{activeChoice ? 'CHANGE PICK' : 'MAKE PICK'}<FaChevronRight
                            size='1.5rem'/></button>
                    </div>
                    {
                        type !== 'numeric' ?
                            <img
                                src={getCardImagePath(activeChoice)}
                                alt={`${activeChoice} splash art`}
                                draggable={false}
                                style={type === 'team' ? {objectFit: 'contain'} : {objectFit: 'cover'}}
                            /> :
                            <h1 style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -60%)'}}>
                                {activeChoice}
                            </h1>
                    }
                    {type === 'item' || type === 'dragon' ?
                        <img src={getCardImagePath(activeChoice)} className='item-center-icon' alt={`${activeChoice} icon`}/> :
                        null
                    }
                </div>

                <div className='question-counter'>
                    <h3>
                        Question {questionIndex + 1} / {questionsLength}
                    </h3>

                </div>
            </div>

            <div className={`pick-modal ${modalOpen ? 'open' : ''}`} ref={modalRef}>
                <div className='pick-modal-content'>
                    {
                        type === 'numeric' ?

                            <>
                                <div className='modal-title'>
                                    <span>{questionText}</span>
                                    <span className='close-button' onClick={handleCancel}><FaTimes/></span>
                                </div>
                                <input
                                    className='numerical-input'
                                    type='number'
                                    defaultValue={activeChoice ? activeChoice : '0'}
                                    onChange={(event) => {
                                        setSubmitEnabled(true);
                                        handleInput(event);
                                }
                                }/>
                                <button onClick={submitEnabled ? handleSave : null} id='submit-button' className={submitEnabled ? `` : 'disabled'}>Submit Choice</button>
                            </> :

                            <>
                                <div className='modal-title'>
                                    <span>{questionText}</span>
                                    <span className='close-button' onClick={handleCancel}><FaTimes/></span>
                                </div>
                                <input type='text' placeholder='Search' onChange={handleInput}/>
                                {renderModalMenu()}
                                <button onClick={submitEnabled ? handleSave : null} id='submit-button' className={submitEnabled ? `` : 'disabled'}>Submit Choice</button>
                            </>
                    }
                </div>
            </div>


            <div className='back-next-container'>
                <button className='back-button' onClick={navigateBack}>
                    <FaChevronLeft/> Back
                </button>
                <button className='next-button' onClick={navigateNext}>
                    Next <FaChevronRight/>
                </button>
            </div>

        </div>
    );
}

export default PickCard;