import * as React from 'react';
import './TournamentDropdown.css';
import {FaCaretDown} from 'react-icons/fa';
import {useContext, useEffect, useRef, useState} from 'react';
import {PickemsContext} from '../../../contexts/PickemsContext';

const TournamentDropdown = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [isOpen, setIsOpen] = useState(false);
    const {tournamentId, setTournamentId, tournamentIdToText} = useContext(PickemsContext);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
        };

        const handleOutsideClick = (event) => {
            if(dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    }

    const updateTournament = (e) => {
        let target = e.target;

        // In case a click registered on an <h2> inside one of the <li> options, target the parent <li> instead
        if(target.tagName === 'H2') {
            target = target.parentNode;
        }
        const value = target.id;
        setTournamentId(value);
    };

    //TODO: fix aria option role
    return (
        // <div className='tournament-dropdown' onClick={toggleDropdown} ref={dropdownRef}>
        //     <div className='dropdown-title'>
        //         <h2>{tournamentIdToText[tournamentId]}</h2>
        //         <FaCaretDown size={isMobile ? '1.5rem' : '2.125rem'} color='var(--text-color-light)'/>
        //     </div>
        //     <ul className={`dropdown-options ${isOpen ? 'open' : ''}`} role='listbox'>
        //         <li onClick={updateTournament} id='MSI_2024' role='option'><h3>MSI 2024</h3></li>
        //         {/*<li id='MSI_2023' className='disabled' role='option'><h3>MSI 2023</h3></li>*/}
        //         <li id='MSI_2023' className='disabled' role='option'><h3>More Tournaments Coming Soon</h3></li>
        //     </ul>
        // </div>

        <div className='tournament-dropdown' ref={dropdownRef} style={{cursor: 'default'}}>
            <h2>{tournamentIdToText[tournamentId]}</h2>
        </div>
    );
}

export default TournamentDropdown;