import * as React from 'react';
import './CombinedTitleSection.css';
import TournamentDropdown from '../../common/TournamentDropdown/TournamentDropdown';
import StageSelector from '../StageSelector/StageSelector';

// Renders a title section consisting of a TournamentDropdown and StageSelector
const CombinedTitleSection = () => {
    return (
        <div className='combined-title-section'>
            <TournamentDropdown/>
            <StageSelector/>
        </div>
    );
 }

export default CombinedTitleSection;