import './Login.css';
import React, {useState, useEffect, useContext} from 'react'
import {Auth} from '@supabase/auth-ui-react'
import {ThemeSupa} from '@supabase/auth-ui-shared'
import {SessionContext} from "../contexts/SessionContext";
import Navbar from "../components/common/Navbar/Navbar";
import Profile from '../components/login/Profile/Profile';
import {Navigate} from 'react-router-dom';

export default function Login() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const baseURL = (environment && environment === "localhost") ? 'http://localhost:3000' : 'https://idols.gg';

    const {
        session,
        isSessionLoading,
        login,
        logout,
        supabaseClient,
        getDiscordUserId,
        getGlobalUserId,
        userHasProvider,
        idolsProfile,
        updateDisplayName
    } = useContext(SessionContext);

    useEffect(() => {
        if (!isSessionLoading) {
            // do we need to do anything here? call linkUser?
        } else {
            // still loading
        }
    }, []);

    if (!session && !isSessionLoading) {
        return (
            <div className='content'>
                <Navbar/>
                <Auth supabaseClient={supabaseClient}
                      appearance={{theme: ThemeSupa}}
                      providers={['google', 'discord']}
                      redirectTo={`${baseURL}/login`}/>
            </div>
        )
    } else if (session && !isSessionLoading) {
        //TODO: replace this with something useful
        // also, where do they set their username? Profile page? Should we have a link to it?
        return (
            <Navigate replace to='/profile'/>
        )
    }
}