import * as React from 'react';
import './Gallery.css';
import GalleryCard from './GalleryCard';
import {Link} from 'react-router-dom';
import {FaWindowMaximize} from 'react-icons/fa';
import {useContext} from "react";
import {PickemsContext} from "../../../contexts/PickemsContext";
import Footer from '../../common/Footer/Footer';

const Gallery = () => {
    // These are equivalent to the serverQuestions.questionList object, but only including questions of a certain type
    const {championQuestions, itemQuestions, playerQuestions, teamQuestions, sideQuestion, dragonQuestion, numericQuestion} = useContext(PickemsContext);

    return (
        <div className='gallery-container'>
            <div className='gallery-section'>
                <h4 style={{fontStyle: 'italic'}}>CHAMPIONS</h4>

                <div className='cards'>
                    {
                        championQuestions &&
                        Object.keys(championQuestions).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                </div>
            </div>

            <div className='gallery-section'>
                <h4 style={{fontStyle: 'italic'}}>PLAYERS</h4>

                <div className='cards'>
                    {
                        playerQuestions &&
                        Object.keys(playerQuestions).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                </div>
            </div>

            <div className='gallery-section'>
                <h4 style={{fontStyle: 'italic'}}>TEAMS</h4>

                <div className='cards'>
                    {
                        teamQuestions &&
                        Object.keys(teamQuestions).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                </div>
            </div>

            <div className='gallery-section'>
                <h4 style={{fontStyle: 'italic'}}>MISCELLANEOUS</h4>

                <div className='cards'>
                    {
                        sideQuestion &&
                        Object.keys(sideQuestion).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                    {
                        dragonQuestion &&
                        Object.keys(dragonQuestion).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                </div>
            </div>

            <div className='gallery-section'>
                <h4 style={{fontStyle: 'italic'}}>TIEBREAKER</h4>

                <div className='cards'>
                    {
                        numericQuestion &&
                        Object.keys(numericQuestion).map(qIndex => (
                            <GalleryCard key={qIndex} questionIndex={qIndex}/>
                        ))
                    }
                </div>
            </div>

            <Footer/>

            <Link to='/pickems/crystal-ball/single' id='single-card-button'><FaWindowMaximize size={24}/>Single card view</Link>
        </div>
    );
}

export default Gallery;