import {createContext, useEffect, useState, useContext} from "react";
import {SessionContext} from "./SessionContext";
import {useToasts} from "./ToastContext";

export const PickemsContext = createContext(null);

const PickemsContextProvider = ({children}) => {

    const environment = process.env.REACT_APP_ENVIRONMENT;
    const baseURL = (environment && environment === "localhost") ? 'http://localhost:8900' : 'https://api.idols.gg';

    const [tournamentId, setTournamentId] = useState('msi2024');

    // Login data
    const {session, isSessionLoading, getGlobalUserId} = useContext(SessionContext);
    const { addToast } = useToasts();

    // Database data
    const [serverQuestions, setServerQuestions] = useState(null);
    const [championQuestions, setChampionQuestions] = useState(null);
    const [itemQuestions, setItemQuestions] = useState(null);
    const [playerQuestions, setPlayerQuestions] = useState(null);
    const [teamQuestions, setTeamQuestions] = useState(null);
    const [sideQuestion, setSideQuestion] = useState(null);
    const [dragonQuestion, setDragonQuestion] = useState(null);
    const [numericQuestion, setNumericQuestion] = useState(null);
    const [numberSubmitted, setNumberSubmitted] = useState(0);


    const [userPicks, setUserPicks] = useState({});
    const [results, setResults] = useState(null);

    const tournamentIdToText = {
        msi2023: 'MSI 2023',
        msi2024: 'MSI 2024'
    }

    const isEmpty = (myObject) => {
        if (!myObject) {
            return true;
        }

        return Object.keys(myObject).length === 0;
    }

    const fetchServerQuestions = () => {
        try {
            const params = {
                serverId: '1077434526571180072',
                tournamentId: 'MSI_2024'
            }

            fetch(`${baseURL}/v2/serverpickem?` + new URLSearchParams(params))
                .then((res) => {
                    if (res.ok) {
                        res.json().then((jsonBody) => {
                            setServerQuestions(jsonBody.data);

                            // Parse the questions into the different sections, for use in Gallery.js
                            setChampionQuestions(getQuestionsOfType(jsonBody.data, 'champion'));
                            setItemQuestions(getQuestionsOfType(jsonBody.data, 'item'));
                            setPlayerQuestions(getQuestionsOfType(jsonBody.data, 'player'));
                            setTeamQuestions(getQuestionsOfType(jsonBody.data, 'team'));
                            setSideQuestion(getQuestionsOfType(jsonBody.data, 'side'));
                            setDragonQuestion(getQuestionsOfType(jsonBody.data, 'dragon'));
                            setNumericQuestion(getQuestionsOfType(jsonBody.data, 'numeric'));
                        })
                    }
                })
                .catch()
        } catch (err) {
            console.log(err);
        }
    }

    const getServerQuestionData = (questionIndex) => {
        if (!serverQuestions) {
            return null;
        }

        return serverQuestions.questionList[questionIndex];
    }

    const getServerQuestionLength = () => {
        if (!serverQuestions || !serverQuestions.questionList) {
            return 0;
        }

        return Object.keys(serverQuestions.questionList).length;
    }

    const getQuestionsOfType = (data = serverQuestions , desiredType) => {
        if (!data || !data.questionList) {
            return null;
        }

        let toReturn = {};

        for (const key in data.questionList) {
            if (data.questionList[key].questionType === desiredType) {
                toReturn[key] = data.questionList[key];
            }
        }

        return toReturn;
    }

    const fetchUserPicks = () => {
        // If user is logged in, fetch picks from the database
        if (session) {
            try {
                const params = {
                    userId: getGlobalUserId(),
                    serverId: '1077434526571180072',
                    tournamentId: 'MSI_2024'
                }

                fetch(`${baseURL}/v2/userpickem?` + new URLSearchParams(params))
                    .then((res) => {
                        if (res.ok) {
                            res.json().then((jsonBody) => {
                                if (jsonBody && jsonBody.data) {
                                    setUserPicks(jsonBody.data);
                                } else {
                                    // If nothing was stored in the database, fetch picks from the local storage
                                    const userPicksFromLocalStorage = localStorage.getItem('userPicks');
                                    const parsedUserPicks = (userPicksFromLocalStorage) ? JSON.parse(userPicksFromLocalStorage) : null;

                                    if (parsedUserPicks) {
                                        setUserPicks(parsedUserPicks);
                                    }
                                }
                            })
                        }
                    })
                    .catch()
            } catch (err) {
                console.log(err);
            }
        } else {
            // If user is not logged in, fetch picks from the local storage
            const userPicksFromLocalStorage = localStorage.getItem('userPicks');
            const parsedUserPicks = (userPicksFromLocalStorage) ? JSON.parse(userPicksFromLocalStorage) : null;

            if (parsedUserPicks) {
                setUserPicks(parsedUserPicks);
            }
        }
    };

    const getSingleUserPick = (questionIndex) => {
        if (isEmpty(userPicks) || !userPicks.userAnswers) {
            return null;
        }

        return userPicks.userAnswers[questionIndex];
    }

    const postUserPicks = () => {
        // // Don't post empty userpicks
        // if (isEmpty(userPicks)) {
        //     return;
        // }
        //
        // // Write picks to local storage
        // if (userPicks) {
        //     const serializedUserPicks = JSON.stringify(userPicks);
        //     if (serializedUserPicks) {
        //         localStorage.setItem('userPicks', serializedUserPicks);
        //     }
        // }
        //
        // // If user is logged in, then we also write to the database
        // const globalUserId = getGlobalUserId();
        //
        // // Don't post if user not logged in
        // if (!globalUserId) {
        //     return;
        // }
        //
        // // Only post if we have data to upload
        // if (userPicks.userAnswers || userPicks.playinBracketAnswers) {
        //     const userPickemId = `${globalUserId}-1077434526571180072-MSI_2024`;
        //     const toUpload = {...userPicks, id: userPickemId};
        //
        //     fetch(`${baseURL}/v2/userpickem`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify(toUpload)  // Convert the JavaScript object to a JSON string
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 console.log('Network response was not ok');
        //             }
        //         })
        //         .catch(error => {
        //             console.error('Error:', error);  // Handle any errors
        //         });
        // }
    }

    const updateSingleUserPick = (questionIndex, selection) => {
        addToast('MSI has started, Crystal Ball picks are locked', 'warning');

        // setUserPicks(userPicks => ({
        //     ...userPicks,
        //     userAnswers: {
        //         ...userPicks.userAnswers,
        //         [questionIndex]: {userChoice: selection}
        //     }
        // }));
    }

    const updateBracketUserPick = (groupName, bracket) => {
        addToast('MSI has started, Play-in brackets are locked', 'warning');

        // setUserPicks(userPicks => ({
        //     ...userPicks,
        //     playinBracketAnswers: {
        //         ...userPicks.playinBracketAnswers,
        //         [groupName]: bracket
        //     }
        // }));
    }

    useEffect(() => {
        fetchServerQuestions();
    }, []);

    useEffect(() => {
        postUserPicks();
    }, [userPicks, session]);

    useEffect(() => {
        if (!isSessionLoading) {
            fetchUserPicks();
        }
    }, [session, isSessionLoading]);

    return (
        <PickemsContext.Provider
            value={{
                userPicks,
                results,
                tournamentId,
                setTournamentId,
                tournamentIdToText,
                serverQuestions,
                getServerQuestionData,
                getServerQuestionLength,
                getSingleUserPick,
                postUserPicks,
                updateSingleUserPick,
                isEmpty,
                championQuestions,
                itemQuestions,
                playerQuestions,
                teamQuestions,
                sideQuestion,
                dragonQuestion,
                numericQuestion,
                numberSubmitted,
                setNumberSubmitted,
                updateBracketUserPick
            }}>
            {children}
        </PickemsContext.Provider>
    );
}

export default PickemsContextProvider;