import React, {useState, useEffect, useContext, useRef} from 'react'
import {SessionContext} from "../contexts/SessionContext";
import Navbar from "../components/common/Navbar/Navbar";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import './Upgrade.css';
import {useToasts} from '../contexts/ToastContext';
import Footer from '../components/common/Footer/Footer';
import {FaCheck, FaTimes} from 'react-icons/fa';

export default function Upgrade() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const baseURL = (environment && environment === "localhost") ? 'http://localhost:8900' : 'https://api.idols.gg';

    const stripe = useStripe();
    const [amount, setAmount] = useState(null);
    const [animate, setAnimate] = useState(false);
    const paymentRef = useRef(null);

    const {
        session,
        isSessionLoading,
        login,
        logout,
        supabaseClient,
        getDiscordUserId,
        getGlobalUserId,
        userHasProvider,
        idolsProfile,
        updateDisplayName,
        updateIdolsProfileAccountType
    } = useContext(SessionContext);
    const { addToast } = useToasts();

    const triggerAnimation = () => {
        setAnimate(true);
        // Optionally reset the animation by removing the class after the animation completes
        setTimeout(() => {
            setAnimate(false);
        }, 2000); // Assuming the duration of your animation is 2000ms (2 seconds)
    };

    const handleCheckout = async () => {
        if(!session || isSessionLoading) {
            addToast('You must be logged in to upgrade your account!', 'failure');
            return;
        }

        const { data } = await axios.post(`${baseURL}/create-checkout-session`, {
            amount: parseFloat(amount) * 100, // Convert dollars to cents
        });

        // Redirect to Stripe Checkout
        window.location = stripe.redirectToCheckout({ sessionId: data.id });
    };

    const highlightPaymentSection = () => {
        if (paymentRef.current) {

            paymentRef.current.scrollIntoView({
                behavior: 'smooth',  // Define the transition animation
                block: 'start',      // Vertical alignment to the top of the viewport
                inline: 'nearest'    // Horizontal alignment
            });

            triggerAnimation();
        }
    };


    // We are logged in
    const queryParams = new URLSearchParams(window.location.search);
    const queryStatus = queryParams?.get('status');
    const queryProduct = queryParams?.get('product');
    const sessionId = queryParams?.get('session_id');


    // Payment was successful. Upgrade their account
    if (queryParams && queryStatus === 'success') {
        if (idolsProfile && idolsProfile.account_type.toLowerCase() !== 'founder') {
            updateIdolsProfileAccountType(queryProduct);
        }

        // addToast('Your account was successfully upgraded!', 'success');

        const newRole = (queryProduct === 'founder' || (idolsProfile && idolsProfile.account_type.toLowerCase() === 'founder')) ? 'Founder'
            : (queryProduct === 'premium') ? 'Premium' : 'Donor';
        return (
            <>
                <div className='content'>
                    <Navbar/>
                    <h3>Thank you for donating!</h3>
                    <p>Your contribution helps us a lot. We hope to use the funds to improve the site and offer even larger prizes in the future.</p>
                    <p>Your account status was upgraded to: {newRole}</p>
                </div>
                <Footer/>
            </>
        )
    } else { // Either payment was cancelled or it's their first time on the page. We don't make a distinction bc we dont check status === 'cancelled'
        return (
            <>
                <div className='content'>
                    <Navbar/>

                    <div className='prizes-wrapper'>
                        <div className='prizes-container'>
                            <div className='prizes-title'>
                                <h2>Prizes & Tiers</h2>
                                <p>
                                    All users are granted entry into the tournament for free. But we encourage you to
                                    contribute at least $1 to receive even more benefits, or at least $10 for Founder status!
                                </p>
                            </div>

                            <div className='membership-tier-section'>
                                <div className='membership-tier-card basic'>
                                    <div  className='membership-card-content'>
                                        <div className='card-title-section'>
                                            <h4>Basic</h4>
                                            <p>All users start with a Basic membership</p>
                                        </div>


                                        <ul className='features-list'>
                                            <li>
                                    <span className='list-check-item'>
                                        <FaCheck/>
                                        <b>Prize Eligibility:</b>
                                    </span>
                                                <ul className='prize-list'>
                                                    <li>
                                                        1st place: $20
                                                    </li>
                                                    <li>
                                                        2nd place: $10
                                                    </li>
                                                    <li>
                                                        3rd place: $5
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                        <span className='list-x-item'>
                                            <FaTimes/>
                                            <b>Premium Prize Eligibility</b>
                                        </span>
                                            </li>
                                            <li>
                                        <span className='list-x-item'>
                                            <FaTimes/>
                                            <b>Profile Flair</b>
                                        </span>
                                            </li>
                                            <li>
                                        <span className='list-x-item'>
                                            <FaTimes/>
                                            <b>Lifetime access to Idols.gg tournaments</b>
                                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='membership-tier-card premium' onClick={highlightPaymentSection}>
                                    <div className='membership-card-content'>
                                        <div className='card-title-section'>
                                            <h4 style={{color: 'var(--silver)'}}>Premium ($1+)</h4>
                                            <p>Leaderboard flair and more prizes</p>
                                        </div>

                                        <ul className='features-list'>
                                            <li>
                                    <span className='list-check-item'>
                                        <FaCheck/>
                                        <b>Premium Prize Eligibility:</b>
                                    </span>

                                                <ul className='prize-list'>
                                                    <li>
                                                        1st place: $100
                                                    </li>
                                                    <li>
                                                        2nd place: $50
                                                    </li>
                                                    <li>
                                                        3rd place: $25
                                                    </li>
                                                    <li>
                                                        4th-10th place: $10 each
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                        <span className='list-check-item'>
                                            <FaCheck/>
                                            <b>Premium Profile Flair</b>
                                        </span>
                                            </li>
                                            <li>
                                        <span className='list-x-item'>
                                            <FaTimes/>
                                            <b>Lifetime access to Idols.gg tournaments</b>
                                        </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <button className='primary-button'  onClick={highlightPaymentSection}>Upgrade Now</button>
                                </div>
                                <div className='membership-tier-card founder'  onClick={highlightPaymentSection}>
                                    <div className='membership-card-content'>
                                        <div className='card-title-section'>
                                            <h4 style={{color: 'var(--gold)'}}>Founder ($10+)</h4>
                                            <p>Support the site and gain <b>lifetime access</b> to premium features</p>
                                        </div>

                                        <ul className='features-list'>
                                            <li>
                                    <span className='list-check-item'>
                                        <FaCheck/>
                                        <b>Premium Prize Eligibility:</b>
                                    </span>

                                                <ul className='prize-list'>
                                                    <li>
                                                        1st place: $100
                                                    </li>
                                                    <li>
                                                        2nd place: $50
                                                    </li>
                                                    <li>
                                                        3rd place: $25
                                                    </li>
                                                    <li>
                                                        4th-10th place: $10 each
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                    <span className='list-check-item'>
                                        <FaCheck/>
                                        <b>Founder Profile Flair</b>
                                    </span>
                                            </li>
                                            <li>
                                    <span className='list-check-item'>
                                        <FaCheck/>
                                        <b>Lifetime access to Idols.gg tournaments</b>
                                    </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <button className='primary-button' onClick={highlightPaymentSection}>Upgrade Now</button>
                                </div>
                            </div>


                            <div className={animate ? 'payment-container animated-border' : 'payment-container'} ref={paymentRef}>
                                <div className='card-title-section'>
                                    <h3>Pay What You Want</h3>
                                    <p>
                                        Idols.gg operates on a pay-what-you-want basis. You choose how much to contribute
                                        based on what you think it's worth!
                                    </p>
                                </div>


                                <input
                                    className='payment-input'
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder="Enter custom amount"
                                    min={1}
                                />

                                <span>Suggested Amounts:</span>

                                <ul className='suggested-options'>
                                    <li><button className='payment-option-button' onClick={() => setAmount(1)}>$1</button></li>
                                    <li><button className='payment-option-button' onClick={() => setAmount(5)}>$5</button></li>
                                    <li><button className='payment-option-button' onClick={() => setAmount(10)}>$10</button></li>
                                    <li><button className='payment-option-button' onClick={() => setAmount(20)}>$20</button></li>
                                </ul>
                                <button onClick={handleCheckout} className='primary-button'>Donate</button>
                            </div>
                        </div>

                        <Footer/>
                    </div>

                </div>


            </>
        )
    }

}