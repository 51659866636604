import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import ToastManager from '../components/common/Toast/ToastManager';

// Create the context
const ToastContext = createContext();

// Creating the Toast Context
// Provider in your app
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = (message, type) => {
        const id = Math.random().toString(36).substring(2, 9);
        setToasts(toasts => [...toasts, { id, message, type }]);
    };

    const removeToast = (id) => {
        setToasts(toasts => toasts.filter(toast => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ addToast }}>
            {children}
            <ToastManager toasts={toasts} removeToast={removeToast} />
        </ToastContext.Provider>
    );
};

// Hook to use the context
export const useToasts = () => useContext(ToastContext);